<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Vous rencontrez la fameuse Erreur 404</h1>
            </b-col>
        </b-row>
        <b-row class="text-center">
            <b-col cols="12">
                <p>
                    J'ai l'impression que vous vous êtes perdu sur le site.
                    Vous pouvez cliquer sur l'un des menus ci-dessus, ou bien
                    <b-link to='/'>ici</b-link> pour retrouver votre chemin.
                </p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: '404NotFound',
};
</script>
